import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    baseURL_API: 'http://socamad.systemsoca.com',
    user: null,
    headers: {
      'headers': {
        'Authorization':  'Bearer ',
      }
    }
  },
  getters: {
  },
  mutations: {
    login(state,datos){
      state.user = datos.data;
      state.headers.headers.Authorization = 'Bearer ' + datos.access_token;
    },
    logout(state){
      state.user = null;
      state.headers.headers.Authorization = 'Bearer ';
    },
  },
  actions: {
  },
  modules: {
  }
})
